var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { scrollable: "", persistent: "", "max-width": "300zpx" },
      on: {
        keydown: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          ) {
            return null
          }
          return _vm.cancelAction()
        }
      },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        { attrs: { color: "white", light: "" } },
        [
          _c(
            "v-window",
            {
              attrs: { vertical: "" },
              model: {
                value: _vm.currentWindowIndex,
                callback: function($$v) {
                  _vm.currentWindowIndex = $$v
                },
                expression: "currentWindowIndex"
              }
            },
            [
              _c(
                "v-window-item",
                { attrs: { value: 0 } },
                [
                  _c("v-card-title", { staticClass: "headline" }, [
                    _vm._v("You've already contacted this seeker")
                  ]),
                  _vm.currentWindowIndex == 0 &&
                  _vm.existingThreads &&
                  _vm.existingThreads.length > 0
                    ? _c(
                        "v-card-text",
                        {
                          staticClass: "pa-0",
                          staticStyle: { "maxz-height": "300px" }
                        },
                        [
                          _c(
                            "v-list",
                            { attrs: { "two-line": "", light: "" } },
                            [
                              _c(
                                "v-list-tile",
                                {
                                  on: {
                                    click: function($event) {
                                      return _vm.selectThread(null)
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "v-list-tile-content",
                                    [
                                      _c(
                                        "v-list-tile-title",
                                        {
                                          staticClass:
                                            "txext-xs-center primary--text font-weight-bold"
                                        },
                                        [_vm._v("NEW MESSAGE...")]
                                      ),
                                      _c("v-list-tile-sub-title", [
                                        _c(
                                          "span",
                                          { staticClass: "font-weight-bold" },
                                          [
                                            _vm._v(
                                              "Start a new conversation..."
                                            )
                                          ]
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c("v-divider"),
                              _c(
                                "v-subheader",
                                { staticClass: "primary--text" },
                                [
                                  _vm._v(
                                    "or continue an existing conversation:"
                                  )
                                ]
                              ),
                              _vm._l(_vm.existingThreads, function(thrd) {
                                return [
                                  _c(
                                    "v-list-tile",
                                    {
                                      key: thrd.id,
                                      attrs: { ripple: "", color: "primary" },
                                      on: {
                                        click: function($event) {
                                          return _vm.selectThread(thrd)
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "v-list-tile-content",
                                        [
                                          _c("v-list-tile-title", {
                                            class: {
                                              "font-weight-bold":
                                                thrd.newMessagesCount > 0
                                            },
                                            domProps: {
                                              innerHTML: _vm._s(thrd.subject)
                                            }
                                          }),
                                          _c("v-list-tile-sub-title", [
                                            _c(
                                              "span",
                                              {
                                                class: {
                                                  "font-weight-bold":
                                                    thrd.newMessagesCount > 0
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    thrd.totalMessagesCount +
                                                      " message" +
                                                      (thrd.totalMessagesCount ==
                                                        1,
                                                      "",
                                                      "s")
                                                  )
                                                )
                                              ]
                                            ),
                                            thrd.newMessagesCount > 0
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "font-weight-bold accent--text text--darken-1"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                      —\n                      " +
                                                        _vm._s(
                                                          thrd.newMessagesCount
                                                        ) +
                                                        " new\n                    "
                                                    )
                                                  ]
                                                )
                                              : _vm._e()
                                          ])
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-list-tile-action",
                                        [
                                          _c(
                                            "v-list-tile-action-text",
                                            {
                                              class: {
                                                "font-weight-bold":
                                                  thrd.newMessagesCount > 0
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f("moment")(
                                                    thrd.lastMessageDate,
                                                    "calendar"
                                                  )
                                                )
                                              )
                                            ]
                                          ),
                                          thrd.newMessagesCount == 0
                                            ? _c(
                                                "v-icon",
                                                {
                                                  attrs: {
                                                    color: "grey lighten-1"
                                                  }
                                                },
                                                [_vm._v("drafts")]
                                              )
                                            : _c(
                                                "v-icon",
                                                {
                                                  attrs: {
                                                    color: "yellow darken-2"
                                                  }
                                                },
                                                [_vm._v("mail")]
                                              )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ]
                              })
                            ],
                            2
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _c(
                "v-window-item",
                { attrs: { value: 1 } },
                [
                  _c("v-card-title", { staticClass: "headline" }, [
                    _vm._v("New Message")
                  ]),
                  _c("v-divider"),
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-layout",
                        { attrs: { row: "", wrap: "" } },
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs12: "" } },
                            [
                              _c(
                                "v-form",
                                { ref: "frm" },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      color: "primary",
                                      rules: [
                                        _vm.rules.required,
                                        _vm.rules.maxCounter(100)
                                      ],
                                      maxlength: "100",
                                      label: "Subject"
                                    },
                                    model: {
                                      value: _vm.newThread.subject,
                                      callback: function($$v) {
                                        _vm.$set(_vm.newThread, "subject", $$v)
                                      },
                                      expression: "newThread.subject"
                                    }
                                  }),
                                  _c("v-textarea", {
                                    attrs: {
                                      color: "primary",
                                      rules: [
                                        _vm.rules.required,
                                        _vm.rules.maxCounter(1000)
                                      ],
                                      maxlength: "1000",
                                      rows: "7",
                                      label: "Message"
                                    },
                                    model: {
                                      value: _vm.newThread.message,
                                      callback: function($$v) {
                                        _vm.$set(_vm.newThread, "message", $$v)
                                      },
                                      expression: "newThread.message"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _vm.currentWindowIndex &&
              _vm.existingThreads &&
              _vm.existingThreads.length > 0
                ? _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", flat: "", round: "" },
                      on: {
                        click: function($event) {
                          _vm.currentWindowIndex = 0
                        }
                      }
                    },
                    [_vm._v("Back")]
                  )
                : _vm._e(),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", flat: "", round: "" },
                  on: { click: _vm.cancel }
                },
                [_vm._v("Cancel")]
              ),
              _vm.currentWindowIndex
                ? _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", round: "" },
                      on: { click: _vm.createThread }
                    },
                    [_vm._v("Send")]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }