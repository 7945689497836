



















































import Vue from "vue";
import * as models from "@/models";
import Avatar from "vue-avatar";
import { G } from "@/globals";

export default {
  name: "jobSelectorDialog",
  components: { Avatar: Avatar },

  data: () => ({
    internalJobPostsEx: null,
    dialog: false,
    resolver: <
      (
        value?: models.jobPostExtended | PromiseLike<models.jobPostExtended>
      ) => void
    >null
  }),
  watch: {},
  created: function() {
    //  this.orgs[0].
  },
  mounted: function() {},
  methods: {
    show: async function(
      jobPostsEx: models.jobPostExtended[] | Promise<models.jobPostExtended[]>
    ): Promise<models.jobPostExtended> {
      if (jobPostsEx instanceof Promise) {
        jobPostsEx.then(jpxs => {
          this.internalJobPostsEx = jpxs;
        });
      } else {
        this.internalJobPostsEx = jobPostsEx;
      }
      this.dialog = true;
      return new Promise<models.jobPostExtended>((resolve, reject) => {
        this.resolver = resolve;
      });
    },
    hide: function() {
      this.dialog = false;
    },
    select: function(jpx: models.jobPostExtended) {
      this.resolver(jpx);
      this.hide();
    }
  },
  computed: {}
};
