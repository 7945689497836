import { server } from "@/code/server";
import * as models from "@/models";
import { G } from "@/globals";

export namespace jobs.orgs {
  export class posts {
    static create = (params: {
      orgId: number;
      cityId: string;
      industryId: number;
      title: string;
      description: string;
      isListed: boolean;
      lifeSpan: number;
    }) => {
      console.log(params);
      return posts.jobPostPromiseToExtended(
        server.Post<models.jobPost>(
          "jobs/organizations/posts/create",
          params,
          true
        )
      );
    };

    static getByOrgId = (
      orgId: number,
      includeContext: boolean,
      status: models.jobPostStatusString
    ) => {
      return server
        .Post<models.paged<models.jobPost>>(
          "jobs/organizations/posts/getByOrganizationId",
          {
            orgId: orgId,
            includeContext: !!includeContext,
            status: status,
            skip: 0,
            take: 1000
          },
          true
        )
        .then(pages => pages.items.map(jp => new models.jobPostExtended(jp)));
    };

    static getByOrgId_Paged = (
      orgId: number,
      skip: number,
      take: number,
      includeContext: boolean,
      status: models.jobPostStatusString
    ) => {
      return server
        .Post<models.paged<models.jobPost>>(
          "jobs/organizations/posts/getByOrganizationId",
          {
            orgId: orgId,
            includeContext: !!includeContext,
            status: status,
            skip: skip,
            take: take
          },
          true
        )
        .then(pages => {
          let result = <models.paged<models.jobPostExtended>>{
            hasNext: pages.hasNext,
            hasPrevious: pages.hasPrevious,
            items: pages.items.map(jp => new models.jobPostExtended(jp)),
            skipped: pages.skipped,
            taken: pages.taken
          };
          return result;
        });
    };

    static delete = (jpId: number) => {
      return server.Post<boolean>(
        "jobs/organizations/posts/delete",
        { id: jpId },
        true
      );
    };

    static getById = (jpId: number) => {
      return posts.jobPostPromiseToExtended(
        server.Post<models.jobPost>(
          "jobs/organizations/posts/getById",
          { id: jpId },
          true
        )
      );
    };

    static extendAndPublish = (jpId: number, lifeSpan: number) => {
      return posts.jobPostPromiseToExtended(
        server.Post<models.jobPost>(
          "jobs/organizations/posts/extendAndPublish",
          { id: jpId, lifeSpan: lifeSpan },
          true
        )
      );
    };

    static unlist = (jpId: number) => {
      return posts.jobPostPromiseToExtended(
        server.Post<models.jobPost>(
          "jobs/organizations/posts/unlist",
          { id: jpId },
          true
        )
      );
    };

    private static jobPostPromiseToExtended(promise: Promise<models.jobPost>) {
      return promise.then(jp => new models.jobPostExtended(jp));
    }
    //
  }

  export class labels {
    static ignore = (seekerId: number, jobPostId: number) => {
      return server.Post<models.seekerLabel>(
        "jobs/organizations/labels/ignore",
        { seekerId, jobPostId },
        true
      );
    };
    static unIgnore = (seekerId: number, jobPostId: number) => {
      return server
        .Post<models.seekerLabel>(
          "jobs/organizations/labels/ignore",
          { seekerId, jobPostId, removeLabel: true },
          true
        )
        .then(() => {
          return <models.seekerLabel>null;
        });
    };

    static shortList = (seekerId: number, jobPostId: number) => {
      return server.Post<models.seekerLabel>(
        "jobs/organizations/labels/shortList",
        { seekerId, jobPostId },
        true
      );
    };
    static unShortList = (seekerId: number, jobPostId: number) => {
      return server
        .Post<models.seekerLabel>(
          "jobs/organizations/labels/shortList",
          { seekerId, jobPostId, removeLabel: true },
          true
        )
        .then(() => {
          return <models.seekerLabel>null;
        });
    };
    static save = (seekerId: number, orgId: number) => {
      return server.Post<models.seekerLabel>(
        "jobs/organizations/labels/save",
        { seekerId, orgId },
        true
      );
    };
    static unSave = (seekerId: number, orgId: number) => {
      return server
        .Post<models.seekerLabel>(
          "jobs/organizations/labels/save",
          { seekerId, orgId, removeLabel: true },
          true
        )
        .then(() => {
          return <models.seekerLabel>null;
        });
    };
  }
}
