
















































































































import Vue from "vue";
import * as models from "@/models";
import { G, rules } from "@/globals";
import { messaging } from "@/code/api.messaging";
import { accounts } from "@/code/api.accounts";

export default {
  name: "newThreadDialog",
  props: {},
  data: () => ({
    dialog: false,
    currentWindowIndex: 0,
    newThread: {
      targetSeekerId: null,
      subject: null,
      message: null
    },
    existingThreads: null,
    resolver: <(value?: models.thread | PromiseLike<models.thread>) => void>(
      null
    ),
    rejecter: <(value?: any | PromiseLike<any>) => void>null,
    rules: {
      required: rules.required,
      maxCounter: rules.maxCounter
      // maxCounter: rules.maxCounter
    }
  }),
  computed: {},
  watch: {},
  created: function() {
    //  this.orgs[0].
  },
  mounted: function() {},
  methods: {
    show: async function(
      targetSeekerId: number,
      existingThreads: models.thread[]
    ): Promise<models.thread> {
      // console.log(existingThreads);
      this.newThread.targetSeekerId = targetSeekerId;
      this.currentWindowIndex = 0;
      this.existingThreads = existingThreads;
      if (!existingThreads || existingThreads.length == 0)
        this.currentWindowIndex = 1;
      this.dialog = true;
      return new Promise<models.thread>((resolve, reject) => {
        this.resolver = resolve;
      });
    },
    hide: function() {
      this.dialog = false;
    },
    selectThread: function(thrd: models.thread) {
      if (thrd) {
        this.$router.push({
          name: "Messages__Message",
          params: { passedThread: <any>thrd }
        });
      } else {
      }
      this.currentWindowIndex = 1;
    },
    createThread: function() {
      let frm: any = this.$refs.frm;
      console.log(frm);

      if (!frm.validate()) return;
      G.showLoading(null, true);
      messaging
        .createThread(
          accounts.currentOrgId,
          this.newThread.targetSeekerId,
          this.newThread.subject,
          [{ text: this.newThread.message }]
        )
        .then(thrd => {
          this.resolver(thrd);
          this.hide();
        })
        .catch(err => {
          G.log.error("newThreadDialog.createThread", {
            error: err,
            thread: this.newThread
          });
          // this.rejecter(err);
        })
        .finally(() => {
          G.hideLoading();
        });
    },

    cancel: function() {
      this.resolver(null);
      this.hide();
    }
  }
};
