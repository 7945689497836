import { render, staticRenderFns } from "./newThreadDialog.vue?vue&type=template&id=70a66572&"
import script from "./newThreadDialog.vue?vue&type=script&lang=ts&"
export * from "./newThreadDialog.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!D:/Workspaces/Evidence/main/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib'
import { VCard } from 'vuetify/lib'
import { VCardActions } from 'vuetify/lib'
import { VCardText } from 'vuetify/lib'
import { VCardTitle } from 'vuetify/lib'
import { VDialog } from 'vuetify/lib'
import { VDivider } from 'vuetify/lib'
import { VFlex } from 'vuetify/lib'
import { VForm } from 'vuetify/lib'
import { VIcon } from 'vuetify/lib'
import { VLayout } from 'vuetify/lib'
import { VList } from 'vuetify/lib'
import { VListTile } from 'vuetify/lib'
import { VListTileAction } from 'vuetify/lib'
import { VListTileActionText } from 'vuetify/lib'
import { VListTileContent } from 'vuetify/lib'
import { VListTileSubTitle } from 'vuetify/lib'
import { VListTileTitle } from 'vuetify/lib'
import { VSpacer } from 'vuetify/lib'
import { VSubheader } from 'vuetify/lib'
import { VTextField } from 'vuetify/lib'
import { VTextarea } from 'vuetify/lib'
import { VWindow } from 'vuetify/lib'
import { VWindowItem } from 'vuetify/lib'
installComponents(component, {VBtn,VCard,VCardActions,VCardText,VCardTitle,VDialog,VDivider,VFlex,VForm,VIcon,VLayout,VList,VListTile,VListTileAction,VListTileActionText,VListTileContent,VListTileSubTitle,VListTileTitle,VSpacer,VSubheader,VTextField,VTextarea,VWindow,VWindowItem})


/* hot reload */
if (module.hot) {
  var api = require("D:\\Workspaces\\Evidence\\main\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!module.hot.data) {
      api.createRecord('70a66572', component.options)
    } else {
      api.reload('70a66572', component.options)
    }
    module.hot.accept("./newThreadDialog.vue?vue&type=template&id=70a66572&", function () {
      api.rerender('70a66572', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/messaging/newThreadDialog.vue"
export default component.exports